import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Chip,
  Divider,
  Grid,
  Stack,
  MenuItem,
  Container,
  IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InterestsIcon from '@mui/icons-material/Interests';
import PrintIcon from '@mui/icons-material/Print';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ItineraryContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "24px",
  padding: theme.spacing(3),
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(10),
  boxShadow: theme.shadows[3],
}));

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const Itinerary = () => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.settings.selectedLanguage);
  const uiLenguage = useSelector((state) => state.settings.uiLanguage);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day'));
  const [interests, setInterests] = useState([]);
  const [newInterest, setNewInterest] = useState('');
  const [budget, setBudget] = useState('');
  const [people, setPeople] = useState('');
  const [itinerary, setItinerary] = useState(null);
  const [loading, setLoading] = useState(false);

  const countries = [
    { name: t('South Korea'), code: 'korean' },
    { name: t('Japan'), code: 'japanese' },
    { name: t('United States'), code: 'english' },
    { name: t('Mexico'), code: 'mexican' },
  ];


  const cities = {
    'korean': [
      { geonameId: '1835848', name: 'Seoul' },
      { geonameId: '1838522', name: 'Busan' },
      { geonameId: '1847458', name: 'Incheon' },
      { geonameId: '1849327', name: 'Gwangju' },
      { geonameId: '1838525', name: 'Daejeon' },
      { geonameId: '1838514', name: 'Ulsan' },
      { geonameId: '1842710', name: 'Jeonju' },
      { geonameId: '1841766', name: 'Changwon' },
      { geonameId: '1842327', name: 'Gumi' },
      { geonameId: '1835847', name: 'Suwon' },
    ],
    'japanese': [
      { geonameId: '1850147', name: 'Tokyo' },
      { geonameId: '1863967', name: 'Osaka' },
      { geonameId: '1865210', name: 'Kyoto' },
      { geonameId: '1865211', name: 'Yokohama' },
      { geonameId: '1865816', name: 'Nagoya' },
      { geonameId: '1863965', name: 'Hiroshima' },
      { geonameId: '1863210', name: 'Fukuoka' },
      { geonameId: '1861280', name: 'Kobe' },
      { geonameId: '1863738', name: 'Kumamoto' },
      { geonameId: '2128295', name: 'Sapporo' },
    ],
    'english': [
      { geonameId: '5128581', name: 'New York City' },
      { geonameId: '5368361', name: 'Los Angeles' },
      { geonameId: '4887398', name: 'Chicago' },
      { geonameId: '4699066', name: 'Houston' },
      { geonameId: '4671654', name: 'Austin' },
      { geonameId: '5308655', name: 'Phoenix' },
      { geonameId: '4726206', name: 'San Antonio' },
      { geonameId: '5392171', name: 'San Diego' },
      { geonameId: '5419384', name: 'Denver' },
      { geonameId: '4164138', name: 'Miami' }
    ],
    'mexican': [
      { geonameId: '3530597', name: 'Mexico City' },
      { geonameId: '4013706', name: 'Guadalajara' },
      { geonameId: '4005539', name: 'Monterrey' },
      { geonameId: '4014338', name: 'Puebla' },
      { geonameId: '4004898', name: 'Merida' },
      { geonameId: '4000900', name: 'Cancun' },
      { geonameId: '4011743', name: 'Oaxaca' },
      { geonameId: '4004897', name: 'Tijuana' },
      { geonameId: '4004896', name: 'Toluca' },
      { geonameId: '4012176', name: 'Queretaro' },
      { geonameId: 'xxxx', name: 'Cd Juarez' },
    ],
  };

  async function handleGenerateItinerary() {
      setLoading(true);
      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'system',
                content: `You are a travel agent helping a client plan a trip. The client wants to visit the city of ${selectedCity}, ${selectedCountry}. They are interested in ${interests.join(', ')}. The trip will start on ${startDate.format('MMMM D, YYYY')} and end on ${endDate.format('MMMM D, YYYY')}. Budget: ${budget ? budget : 'not specified'}. Number of people: ${people ? people : 'not specified'}. Please create an itinerary for the trip that includes recommendations for activities, restaurants, and hotels, tips for getting around, and any other relevant information and generate the itinerary in ${uiLenguage}.`
              },
            ],
            max_tokens: 900,
            temperature: 0.7,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );

        const result = response.data.choices[0].message.content.trim();
        setItinerary(result);
      } catch (error) {
        console.error('Error al generar el itinerario:', error);
      } finally {
        setLoading(false);
      }
    }

  const handleAddInterest = () => {
    if (newInterest.trim() !== '') {
      if (newInterest.includes(',')) {
        const interestsArray = newInterest.split(',');
        interestsArray.forEach((interest) => {
          setInterests((prev) => [...prev, interest.trim()]);
        });
      } else {
        setInterests((prev) => [...prev, newInterest.trim()]);
      }
      setNewInterest('');
    }
  };

  const handleDeleteInterest = (interestToDelete) => {
    setInterests((prev) => prev.filter((interest) => interest !== interestToDelete));
  };

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Itinerario</title>');
    printWindow.document.write(`
      <style>
        body {
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
        }
        h1 {
          text-align: center;
          color: #0056b3;
        }
        .content {
          margin: 20px;
        }
        .itinerary {
          border: 1px solid #ddd;
          padding: 15px;
          border-radius: 8px;
          background-color: #f5f5f5;
        }
        .footer {
          text-align: center;
          margin-top: 20px;
          font-size: 0.9em;
          color: #777;
        }
      </style>
    `);
    printWindow.document.write('</head><body >');
    printWindow.document.write('<h1>' + t('travel_itinerary') + '</h1>');
    printWindow.document.write('<div class="content">');
    printWindow.document.write('<div class="itinerary">' + itinerary.replace(/\n/g, '<br>') + '</div>');
    printWindow.document.write('</div>');
    printWindow.document.write('<div class="footer">');
    printWindow.document.write(t('generated_by'));
    printWindow.document.write('</div>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const handleShareWhatsApp = () => {
    const url = `https://wa.me/?text=${encodeURIComponent(itinerary)}`;
    window.open(url, '_blank');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ItineraryContainer>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            {t('generate_itinerary_with_ai')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {t('plan_your_trip_intelligently')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            <CalendarTodayIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            {t('select_dates')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label={t('start_date')}
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={t('end_date')}
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            <PlaceIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            {t('select_country')}
          </Typography>
          <TextField
            select
            label={t('country')}
            fullWidth
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.name} value={country.code}>
                {country.name}
              </MenuItem>
            ))}

          </TextField>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            <PlaceIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            {t('select_city')}
          </Typography>
          <TextField
            select
            label={t('city')}
            fullWidth
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            {cities[selectedCountry]?.map((city) => (
              <MenuItem key={city.geonameId} value={city.name}>
                {city.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            <InterestsIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
            {t('add_your_interests')}
          </Typography>
          <Stack direction="row" spacing={1} mb={2} sx={{ flexWrap: 'wrap', gap: 0.5 }}>
            {interests.map((interest) => (
              <Chip
                key={interest}
                label={interest}
                onDelete={() => handleDeleteInterest(interest)}
                color="primary"
              />
            ))}
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={10} xl={11}>
              <TextField
                label={t('interests')}
                variant="outlined"
                fullWidth
                value={newInterest}
                onChange={(e) => setNewInterest(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleAddInterest()}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2} xl={1}>
              <Button
                onClick={handleAddInterest}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ height: '100%' }}
              >
                {t('add')}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('additional_information')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('budget')}
                variant="outlined"
                fullWidth
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t('number_of_people')}
                variant="outlined"
                fullWidth
                value={people}
                onChange={(e) => setPeople(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Button
            onClick={handleGenerateItinerary}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<TravelExploreIcon />}
            disabled={loading}
          >
            {t('generate_itinerary')}
          </Button>
        </Box>

        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" my={3}>
            <CircularProgress />
          </Box>
        )}

        {itinerary && (
          <Box mt={3} sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2 }}>
            <Stack direction="row" spacing={1} justifyContent="center">
              <IconButton onClick={handlePrint} color="primary">
                <PrintIcon />
              </IconButton>
              <IconButton onClick={handleShareWhatsApp} color="success">
                <WhatsAppIcon />
              </IconButton>
            </Stack>
            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
              {t('generated_itinerary')}
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {itinerary}
            </Typography>

            <Box mt={2} sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
              <Stack direction="row" spacing={1} justifyContent="center">
                <IconButton onClick={handlePrint} color="primary">
                  <PrintIcon />
                </IconButton>
                <IconButton onClick={handleShareWhatsApp} color="success">
                  <WhatsAppIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        )}
      </ItineraryContainer>
    </LocalizationProvider>
  );
};

export default Itinerary;
