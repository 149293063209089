import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ bottom: 0, position: 'relative', width: '100%', bgcolor: 'background.paper', py: 2 }} component={'footer'}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
          <Link href="#" variant="body2" onClick={() => handleNavigation('/')}>
            {t('home')}
          </Link>
          <Box mx={2}>|</Box>
          <Link href="#" variant="body2" onClick={() => handleNavigation('/itinerary')}>
            {t('generate_itinerary')}
          </Link>
          <Box mx={2}>|</Box>
          <Link href="#" variant="body2" onClick={() => handleNavigation('/currency')}>
            {t('convert_currency')}
          </Link>
          <Box mx={2}>|</Box>
          <Link href="#" variant="body2" onClick={() => handleNavigation('/about')}>
            {t('about_us')}
          </Link>
          <Box mx={2}>|</Box>
          <Link href="#" variant="body2" onClick={() => handleNavigation('/contact')}>
            {t('contact_us')}
          </Link>
          <Box mx={2}>|</Box>
          <Link href="#" variant="body2" onClick={() => handleNavigation('/policies')}>
            {t('policies')}
          </Link>
        </Box>
        <Typography variant="body2" color="textSecondary" align="center">
          © {new Date().getFullYear()} Traventura. {t('all_rights_reserved')}.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
