import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import phrasesData from "../phrases.json";
import {
  Button,
  Container,
  Typography,
  TextField,
  Grid,
  IconButton,
  Box,
  Paper,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MicIcon from '@mui/icons-material/Mic';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLanguage } from '../features/settings/settingsSlice';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const categoryIcons = {
  "Salud y Cortesía": "💊",
  "Afirmación y Negación": "👍",
  "Comunicación Básica": "📞",
  "Ayuda y Emergencias": "🚨",
  "Restaurantes y Comida": "🍴",
  "Transporte": "🚗",
  "Numeros": "🔢"
};

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedLanguage, uiLanguage } = useSelector((state) => state.settings);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPhrase, setSelectedPhrase] = useState(null);
  const [inputPhrase, setInputPhrase] = useState("");
  const [translatedPhrase, setTranslatedPhrase] = useState(null);
  const [translatedKorean, setTranslatedKorean] = useState("");
  const [translatedPronunciation, setTranslatedPronunciation] = useState("");
  const [showTags, setShowTags] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const textRef = useRef(null);
  const textRefResult = useRef(null);
  const languageRef = useRef(null);
  const categoryRef = useRef(null);

  useEffect(() => {
    if (shouldScroll && textRef.current) {
      textRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedPhrase(null);
    setTranslatedPhrase(null);
    setTranslatedKorean("");
    setTranslatedPronunciation("");
    setShowTags(false);
    setShouldScroll(true);
    setTimeout(() => {
      if (categoryRef.current) {
        categoryRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 300);
  };

  const handlePlay = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    if (selectedLanguage === 'korean') {
      utterance.lang = 'ko-KR';
    } else if (selectedLanguage === 'japanese') {
      utterance.lang = 'ja-JP';
    } else if (selectedLanguage === 'spanish') {
      utterance.lang = 'es-ES';
    } else {
      utterance.lang = 'en-US';
    }
    speechSynthesis.speak(utterance);
  };

  const handlePhraseClick = (phrase) => {
    setSelectedPhrase(phrase);
    setTranslatedPhrase(null);
    setTranslatedKorean("");
    setTranslatedPronunciation("");
    setShouldScroll(true);
    setTimeout(() => {
      if (textRef.current) {
        textRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 300);
  };

  const handleInputChange = (event) => {
    setInputPhrase(event.target.value);
  };

  const handleTranslate = async () => {
    if (!inputPhrase) {
      toast.error(t('enter_phrase_to_translate'));
      return false;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `Please translate the phrase "${inputPhrase}" into ${selectedLanguage} and provide the translation and pronunciation in JSON format with those same properties {translation, pronunciation}.`,
            },
          ],
          max_tokens: 150,
          temperature: 0.5,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const result = response.data.choices[0].message.content.trim();
      const { translation, pronunciation } = JSON.parse(result);
      setSelectedPhrase(null);
      setTranslatedPhrase(result);
      setTranslatedKorean(translation || t('translation_not_available'));
      setTranslatedPronunciation(
        pronunciation || t('pronunciation_not_available')
      );
      setShouldScroll(true);

      setTimeout(() => {
        if (textRefResult.current) {
          textRefResult.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 500);
    } catch (error) {
      console.error("Error translating phrase:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setSelectedCategory(null);
    setSelectedPhrase(null);
    setTranslatedPhrase(null);
    setTranslatedKorean("");
    setTranslatedPronunciation("");
    setShowTags(true);
    setShouldScroll(true);
    setTimeout(() => {
      if (languageRef.current) {
        languageRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 300);
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage) {
      dispatch(setSelectedLanguage(newLanguage));
      if (languageRef.current) {
        languageRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const handleSpeechInput = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognitionRef.current = recognition;
    recognition.lang = 'en-US'; // Puedes cambiar esto según el idioma que prefieras
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const interimTranscript = Array.from(event.results)
        .map(result => result[0].transcript)
        .join('');
      setInputPhrase(interimTranscript);
    };

    recognition.onerror = (event) => {
      console.error("Error recognizing speech:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.start();
    setIsListening(true);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 14, mb: 18 }}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h3" align="center" gutterBottom mt={3}>
          {t('learn_travel_enjoy')}
        </Typography>
        <Box ref={languageRef}>
          <Typography variant="h5" align="center" gutterBottom fontStyle={{ fontStyle: "italic" }}>
            {t('choose_language')}
          </Typography>
          <ToggleButtonGroup
            value={selectedLanguage}
            exclusive
            onChange={handleLanguageChange}
            aria-label="Language selection"
            sx={{ mb: 2, display: "flex", justifyContent: "center" }}
          >
            {
              uiLanguage === 'english' ? (
                <ToggleButton value="spanish" aria-label="Spanish">
                  ES
                </ToggleButton>
              ) : (
                <ToggleButton value="english" aria-label="English">
                  EN
                </ToggleButton>
              )
            }
            <ToggleButton value="korean" aria-label="Korean">
              KR
            </ToggleButton>
            <ToggleButton value="japanese" aria-label="Japanese">
              JP
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </motion.div>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 4 }}>
          <CircularProgress />
        </Box>
      )}
      <Box mb={2} sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <TextField
          fullWidth
          label={t('enter_phrase')}
          variant="outlined"
          value={inputPhrase}
          onChange={handleInputChange}
        />
        <IconButton
          onClick={handleTranslate}
          color="primary"
          aria-label="translate"
          style={{ border: "1px solid" }}
        >
          <TranslateIcon fontSize="medium" />
        </IconButton>
        <IconButton
          onClick={handleSpeechInput}
          color="primary"
          aria-label="speak"
          style={{ border: "1px solid" }}
        >
          <MicIcon fontSize="medium" />
        </IconButton>
      </Box>

      {showTags && !selectedCategory && (
        <Paper sx={{ p: 2, mb: 12, border: "1px solid #000", borderRadius: "24px" }}>
          <Typography variant="h4" align="center" gutterBottom>
            {t('categories')}
            <Typography align="center" gutterBottom>
              {t('quick_phrases')}
            </Typography>
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            {Object.keys(phrasesData).map((category, index) => (
              <Grid item key={index}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleCategoryClick(category)}
                    size="large"
                    color="primary"
                    sx={{ mb: 1 }}
                    startIcon={<span>{categoryIcons[category]}</span>}
                  >
                    {t(`categories_list.${category}`)}
                  </Button>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {selectedCategory && (
        <>
          <Box mb={2} ref={categoryRef}>
            <Button
              onClick={handleBack}
              startIcon={<ArrowBackIcon />}
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              {t('back_to_categories')}
            </Button>
            <Paper sx={{ p: 2, border: "1px solid #000", borderRadius: "24px" }}>
              <Typography variant="h5" gutterBottom align="center" mb={3}>
                {t(`categories_list.${selectedCategory}`)}
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {phrasesData[selectedCategory].map((phrase, index) => (
                  <Grid item key={index}>
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handlePhraseClick(phrase)}
                        size="large"
                        color="primary"
                        sx={{ mb: 1 }}
                      >
                        {uiLanguage === 'english' ? phrase.english : phrase.spanish}
                      </Button>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Box>
        </>
      )}

      {selectedPhrase && (
        <Paper
          sx={{ p: 2, border: "1px solid #000", borderRadius: "24px", mb: 12 }}
          ref={textRef}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
            justifyContent: "space-between",
          }}
        >
          <Box p={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <Box>
                <Typography variant="h5" gutterBottom>{t('phrase')}: {uiLanguage === 'english' ? selectedPhrase.english : selectedPhrase.spanish}</Typography>
                <Typography variant="h5" gutterBottom>
                  {t('writing')}: {uiLanguage === 'english' ? selectedPhrase[selectedLanguage] : selectedPhrase[selectedLanguage]}
                </Typography>
                <Typography variant="h5" gutterBottom>
                  {t('pronunciation')}: {
                    selectedLanguage === 'spanish' ? selectedPhrase.spanish : selectedPhrase[`${selectedLanguage}_pronunciation`]
                  }
                </Typography>
              </Box>
              <IconButton
                sx={{ border: "1px solid #000" }}
                onClick={() => handlePlay(uiLanguage === 'english' ? selectedPhrase[selectedLanguage] : selectedPhrase[selectedLanguage])}
                aria-label="Play pronunciation"
                size="large"
              >
                <PlayArrowIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Paper>
      )}

      {translatedPhrase && (
        <Box
          my={4}
          mb={12}
          ref={textRefResult}
          sx={{
            border: "1px solid #ddd",
            borderRadius: "24px",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box flexGrow={1}>
            <Typography variant="h5" gutterBottom>
              {translatedKorean}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {translatedPronunciation}
            </Typography>
          </Box>
          <IconButton
            onClick={() => handlePlay(translatedKorean)}
            aria-label="Play pronunciation"
            size="large"
          >
            <PlayArrowIcon fontSize="inherit" />
          </IconButton>
        </Box>
      )}
    </Container>
  );
};

export default Home;
