import React from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Contact = () => {
  const WHATSAPP_PHONE_NUMBER = process.env.REACT_APP_WHATSAPP_PHONE_NUMBER;
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      user_name: '',
      user_email: '',
      message: ''
    },
    validationSchema: Yup.object({
      user_name: Yup.string().required(t('name_required')),
      user_email: Yup.string().email(t('email_invalid')).notRequired(),
      message: Yup.string().required(t('message_required'))
    }),
    onSubmit: (values) => {
      const whatsappMessage = `${t('name')}: ${values.user_name}\n${t('email')}: ${values.user_email}\n${t('message')}: ${values.message}`;
      const whatsappLink = `https://wa.me/${WHATSAPP_PHONE_NUMBER}?text=${encodeURIComponent(whatsappMessage)}`;
      window.open(whatsappLink, '_blank');
    }
  });

  return (
    <Container maxWidth="md" sx={{ mt: 14, mb: 10 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          {t('contact_us')}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            label={t('name')}
            variant="outlined"
            sx={{ mb: 2 }}
            name="user_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.user_name}
            error={formik.touched.user_name && Boolean(formik.errors.user_name)}
            helperText={formik.touched.user_name && formik.errors.user_name}
            required
          />
          <TextField
            fullWidth
            label={t('email')}
            variant="outlined"
            sx={{ mb: 2 }}
            name="user_email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.user_email}
            error={formik.touched.user_email && Boolean(formik.errors.user_email)}
            helperText={formik.touched.user_email && formik.errors.user_email}
          />
          <TextField
            fullWidth
            label={t('message')}
            variant="outlined"
            multiline
            rows={4}
            sx={{ mb: 2 }}
            name="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            required
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" type="submit">
              {t('send_whatsapp')}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default Contact;
