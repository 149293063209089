import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CookiesPopup = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookieAccepted) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setOpen(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        bgcolor: 'background.paper',
        borderTop: '1px solid #000',
        boxShadow: 24,
        zIndex: 999,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography id="cookies-popup-title" variant="h6" component="h2">
        {t('cookies')}
      </Typography>
      <Typography id="cookies-popup-description" sx={{ mt: 1, textAlign: 'center' }}>
        {t('cookies_description')}
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button variant="contained" color="primary" onClick={handleAccept}>
          {t('accept')}
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleDecline}>
          {t('decline')}
        </Button>
      </Box>
    </Box>
  );
};

export default CookiesPopup;
