import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Policies = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ mt: 14, mb: 30 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          {t('policies')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('policies_description')}
        </Typography>
      </Box>
    </Container>
  );
};

export default Policies;
