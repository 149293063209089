import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, MenuItem, Typography, Box, Container, CircularProgress, Card, CardMedia, CardContent } from '@mui/material';

const CountryInfo = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryInfo, setCountryInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        setCountries(response.data.sort((a, b) => a.name.common.localeCompare(b.name.common)));
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetchCountryInfo(selectedCountry);
    }
  }, [selectedCountry]);

  const fetchCountryInfo = async (countryName) => {
    setLoading(true);
    try {
      const countryResponse = await axios.get(`https://restcountries.com/v3.1/name/${countryName}`);
      const countryData = countryResponse.data[0];

      const capital = countryData.capital[0];
      const weatherResponse = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${capital}&appid=${process.env.REACT_APP_WEATHER_API_KEY}&units=metric`);
      const weatherData = weatherResponse.data;

      const timezoneResponse =  "Europe/London";
      const timezoneData = "timezoneResponse.data";

      setCountryInfo({
        name: countryData.name.common,
        capital: countryData.capital[0],
        population: countryData.population,
        region: countryData.region,
        subregion: countryData.subregion,
        languages: Object.values(countryData.languages).join(', '),
        flag: countryData.flags.svg,
        maps: countryData.maps.googleMaps,
        temperature: weatherData.main.temp,
        time: timezoneData.datetime,
      });
    } catch (error) {
      console.error('Error fetching country info:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" gutterBottom align="center" sx={{ mb: 2 }}>
          Country Information
        </Typography>
        <TextField
          select
          label="Select a Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        >
          {countries.map((country) => (
            <MenuItem key={country.cca3} value={country.name.common}>
              {country.name.common}
            </MenuItem>
          ))}
        </TextField>
        {loading && <CircularProgress />}
        {countryInfo && (
          <Card sx={{ width: '100%', maxWidth: 800, mt: 4 }}>
            <CardMedia
              component="img"
              height="200"
              image={countryInfo.flag}
              alt={`Flag of ${countryInfo.name}`}
            />
            <CardContent>
              <Typography variant="h4" gutterBottom>
                {countryInfo.name}
              </Typography>
              <Typography variant="h6">Capital: {countryInfo.capital}</Typography>
              <Typography variant="h6">Population: {countryInfo.population.toLocaleString()}</Typography>
              <Typography variant="h6">Region: {countryInfo.region}</Typography>
              <Typography variant="h6">Subregion: {countryInfo.subregion}</Typography>
              <Typography variant="h6">Languages: {countryInfo.languages}</Typography>
              <Typography variant="h6">Temperature: {countryInfo.temperature}°C</Typography>
              <Typography variant="h6">Local Time: {new Date(countryInfo.time).toLocaleTimeString()}</Typography>
              <Box sx={{ mt: 2 }}>
                <a href={countryInfo.maps} target="_blank" rel="noopener noreferrer">
                  <Typography variant="h6" color="primary">View on Google Maps</Typography>
                </a>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
    </Container>
  );
};

export default CountryInfo;
