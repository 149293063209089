import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box, Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Brightness3 from '@mui/icons-material/Brightness3';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleDarkMode, setSelectedLanguage, setUILanguage } from '../features/settings/settingsSlice';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [uiMenuAnchorEl, setUiMenuAnchorEl] = useState(null);
  const [translateMenuAnchorEl, setTranslateMenuAnchorEl] = useState(null);
  const [weather, setWeather] = useState('🌦️');
  const [weatherDialogOpen, setWeatherDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { uiLanguage, darkMode } = useSelector((state) => state.settings);
  const navigate = useNavigate();

  const getLocationAndFetchWeather = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        fetchWeather(position.coords.latitude, position.coords.longitude);
      }, () => {
        setWeather(t('weather_not_available'));
      });
    } else {
      setWeather(t('weather_not_available'));
    }
  };

  const fetchWeather = async (lat, lon) => {
    const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
    try {
      const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&units=metric`);
      setWeather(`${response.data.main.temp}°C`);
    } catch (error) {
      console.error('Error fetching weather data:', error);
      setWeather(t('weather_not_available'));
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (event, type) => {
    if (type === 'ui') {
      setUiMenuAnchorEl(event.currentTarget);
    } else {
      setTranslateMenuAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = (type) => {
    if (type === 'ui') {
      setUiMenuAnchorEl(null);
    } else {
      setTranslateMenuAnchorEl(null);
    }
  };

  const handleLanguageChange = (language, type, i18nLanguage) => {
    if (type === 'ui') {
      dispatch(setUILanguage(language));
      i18n.changeLanguage(i18nLanguage);
      if (i18nLanguage === 'en-EN') {
        dispatch(setSelectedLanguage('spanish'));
      }
      if (i18nLanguage === 'es-ES') {
        dispatch(setSelectedLanguage('english'));
      }
    } else {
      dispatch(setSelectedLanguage(language));
      getLocationAndFetchWeather();
    }
    handleMenuClose(type);
  };

  const openWeatherDialog = () => {
    setWeatherDialogOpen(true);
  };

  const closeWeatherDialog = () => {
    setWeatherDialogOpen(false);
  };

  const confirmWeatherRequest = () => {
    closeWeatherDialog();
    getLocationAndFetchWeather();
  };

  const menuItems = [
    { text: t('home'), onClick: () => navigate('/') },
    { text: t('itinerary'), onClick: () => navigate('/itinerary') },
    { text: t('currency_converter'), onClick: () => navigate('/currency') },
    { text: t('about_us'), onClick: () => navigate('/about') },
    { text: t('contact_us'), onClick: () => navigate('/contact') },
    { text: t('policies'), onClick: () => navigate('/policies') },
  ];

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" sx={{ color: 'white' }} aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <img src="/logo.png" alt="logo" style={{ height: 70 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => navigate('/')}>
            Traventura
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', right: 0, position: 'absolute' }}>
            <IconButton onClick={(event) => handleMenuClick(event, 'ui')} sx={{ color: 'white', right: -15 }} aria-label="language" aria-controls="ui-language-menu" aria-haspopup="true">
              <LanguageIcon />
            </IconButton>
            <Menu
              id="ui-language-menu"
              anchorEl={uiMenuAnchorEl}
              keepMounted
              open={Boolean(uiMenuAnchorEl)}
              onClose={() => handleMenuClose('ui')}
            >
              <MenuItem onClick={() => handleLanguageChange('english', 'ui', 'en-EN')}>EN</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('spanish', 'ui', 'es-ES')}>ES</MenuItem>
            </Menu>
            <IconButton onClick={(event) => handleMenuClick(event, 'translate')} sx={{ color: 'white' }}>
              <SpatialAudioOffIcon />
            </IconButton>
            <Menu
              id="translate-language-menu"
              anchorEl={translateMenuAnchorEl}
              keepMounted
              open={Boolean(translateMenuAnchorEl)}
              onClose={() => handleMenuClose('translate')}
            >
              <MenuItem
                onClick={() => {
                  if (uiLanguage === 'english') {
                    handleLanguageChange('spanish', 'translate');
                  } else {
                    handleLanguageChange('english', 'translate');
                  }
                }}
              >
                {t('learn')} {uiLanguage === 'english' ? t('spanish') : t('english')}
              </MenuItem>
              <MenuItem onClick={() => handleLanguageChange('korean', 'translate')}>{t('learn_korean')}</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('japanese', 'translate')}>{t('learn_japanese')}</MenuItem>
            </Menu>
            <Typography sx={{ mt: 1, mb: 1,  color: 'white', fontSize: weather === '🌦️' ? 28 : 16 }} onClick={openWeatherDialog}>
              {weather}
            </Typography>
            <IconButton onClick={() => dispatch(toggleDarkMode())} sx={{ color: 'white' }}>
              {darkMode ? <Brightness7Icon /> : <Brightness3 />}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            {menuItems.map((item, index) => (
              <ListItem button key={index} onClick={item.onClick}>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Dialog
        open={weatherDialogOpen}
        onClose={closeWeatherDialog}
      >
        <DialogTitle>{t('request_location_permission')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('location_permission_description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWeatherDialog} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={confirmWeatherRequest} color="primary" autoFocus>
            {t('allow')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
