import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Container, Typography, IconButton, CircularProgress, MenuItem, Select, FormControl, InputLabel,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

const languages = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'ko', name: 'Korean' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'ru', name: 'Russian' },
  { code: 'zh', name: 'Chinese' },
];

const RealTimeTranslator = ({ onStop }) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [sourceLang, setSourceLang] = useState(localStorage.getItem('sourceLang') || 'en');
  const [targetLang, setTargetLang] = useState(localStorage.getItem('targetLang') || 'ko');
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const utteranceRef = useRef(null);

  useEffect(() => {
    if (!recognitionRef.current && 'webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.interimResults = true;
      recognition.maxAlternatives = 1;
      recognitionRef.current = recognition;

      recognition.onresult = (event) => {
        const interimTranscript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        setInputText(interimTranscript);

        if (event.results[0].isFinal) {
          handleTranslate(interimTranscript, localStorage.getItem('sourceLang'), localStorage.getItem('targetLang'));
          setIsListening(false);
        }
      };

      recognition.onerror = (event) => {
        console.error("Error recognizing speech:", event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        if (isListening) {
          recognition.start();
        }
      };
    }
  }, [isListening, sourceLang, targetLang]);

  useEffect(() => {
    if (recognitionRef.current) {
      recognitionRef.current.lang = localStorage.getItem('sourceLang');
    }
  }, [sourceLang]);

  useEffect(() => {
    localStorage.setItem('sourceLang', sourceLang);
    localStorage.setItem('targetLang', targetLang);
  }, [sourceLang, targetLang]);

  useEffect(() => {
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        onStop();
      }
    };
  }, [onStop]);

  const handleTranslate = async (text, source, target) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: `Translate the following text from ${source} to ${target}: "${text}"`,
            },
          ],
          max_tokens: 150,
          temperature: 0.5,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const result = response.data.choices[0].message.content.trim();
      setTranslatedText(result || t('translation_not_available'));
      handlePlay(result || t('translation_not_available'));
    } catch (error) {
      console.error("Error translating text:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSpeechInput = () => {
    setInputText('');
    setTranslatedText('');
    setLoading(false);

    if (recognitionRef.current) {
      recognitionRef.current.lang = sourceLang;
      recognitionRef.current.start();
      setIsListening(true);
      setLoading(true);
    }
  };

  const handleStopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const handlePlay = (text) => {
    speechSynthesis.cancel();
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = localStorage.getItem('targetLang');
    utteranceRef.current = utterance;
    speechSynthesis.speak(utterance);
  };

  const handleLanguageSwap = () => {
    const temp = sourceLang;
    setSourceLang(targetLang);
    setTargetLang(temp);
    localStorage.setItem('sourceLang', targetLang);
    localStorage.setItem('targetLang', temp);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 3 }}>
      <Typography variant="h4" gutterBottom align="center">
        {t('translate_text')}
      </Typography>
      <Typography variant="body1" gutterBottom align="center" mb={4}>
        {t('real_time_translation')}
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>{t('source_language')}</InputLabel>
        <Select
          value={sourceLang}
          onChange={(e) => {
            setSourceLang(e.target.value);
            setInputText('');
            setTranslatedText('');
          }}
          label={t('source_language')}
        >
          {languages.map((lang) => (
            <MenuItem key={lang.code} value={lang.code}>
              {lang.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>{t('target_language')}</InputLabel>
        <Select
          value={targetLang}
          onChange={(e) => {
            setTargetLang(e.target.value);
            setInputText('');
            setTranslatedText('');
          }}
          label={t('target_language')}
        >
          {languages.map((lang) => (
            <MenuItem key={lang.code} value={lang.code}>
              {lang.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', mb: 3 }}>
        <IconButton
          onClick={isListening ? handleStopListening : handleSpeechInput}
          color="primary"
          aria-label="speak"
          style={{ border: "1px solid" }}
        >
          <MicIcon fontSize="medium" />
        </IconButton>
        <IconButton
          onClick={handleLanguageSwap}
          color="primary"
          aria-label="swap languages"
          style={{ border: "1px solid" }}
        >
          <SwapVertIcon fontSize="medium" />
        </IconButton>
        {
          translatedText && (
            <IconButton
              onClick={() => handlePlay(translatedText)}
              color="primary"
              aria-label="play translation"
              style={{ border: "1px solid" }}
            >
              <PlayArrowIcon fontSize="medium" />
            </IconButton>
          )
        }
      </Box>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {inputText && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            {t('you_said')}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {inputText}
          </Typography>
        </Box>
      )}
      {translatedText && (
        <Box sx={{ mt: 4, mb: 3, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="h5" gutterBottom>
            {t('translation')}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {translatedText}
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default RealTimeTranslator;