import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    selectedLanguage: 'english',
    uiLanguage: 'english'
  },
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setUILanguage: (state, action) => {
      state.uiLanguage = action.payload;
    },
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    }
  }
});

export const { setSelectedLanguage, setUILanguage, toggleDarkMode } = settingsSlice.actions;
export default settingsSlice.reducer;
