import React, { useState, useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BottomNav = ({ apiKey, selectedLanguage }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('home');
  const navigate = useNavigate();
  const path = window.location.pathname;

  useEffect(() => {
    switch (path) {
      case '/':
        setValue('home');
        break;
      case '/itinerary':
        setValue('itinerary');
        break;
      case '/currency':
        setValue('currency');
        break;
      case '/destination':
        setValue('destination');
        break;
      default:
        setValue('home');
        break;
    }
  }, [path]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 'home':
        navigate('/');
        break;
      case 'itinerary':
        navigate('/itinerary');
        break;
      case 'currency':
        navigate('/currency');
        break;
      case 'destination':
        navigate('/destination');
        break;
      default:
        break;
    }
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        sx={{ bottom: 0, position: 'fixed', width: '100%' }}
      >
        <BottomNavigationAction label={t('home')} value="home" icon={<HomeIcon />} />
        <BottomNavigationAction label={t('generate_itinerary')} value="itinerary" icon={<EditNoteIcon />} />
        <BottomNavigationAction label={t('convert_currency')} value="currency" icon={<AttachMoneyIcon />} />
        {/* <BottomNavigationAction label={t('destination')} value="destination" icon={<LocalAirportIcon />} /> */}
      </BottomNavigation>
    </>
  );
};

export default BottomNav;
