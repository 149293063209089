import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Home from './components/Home';
import Itinerary from './components/Itinerary';
import Footer from './components/Footer';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import TranslateIcon from '@mui/icons-material/Translate';
import { useSelector } from 'react-redux';
import Navbar from './components/Navbar';
import CurrencyConverter from './components/CurrencyConverter';
import CountryInfo from './components/CountryInfo';
import CookiesPopup from './components/CookiesPopup';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Policies from './components/Policies';
import BottomNav from './components/BottomNav';
import RealTimeTranslator from './components/RealTimeTranslator';
import { Toaster } from 'react-hot-toast';
import './App.css';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

ReactGA.initialize(TRACKING_ID);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#121212',
      paper: '#424242',
    },
    text: {
      primary: '#e0e0e0',
      secondary: '#b0b0b0',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '8px 16px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#424242',
          '& .MuiInputBase-input': {
            color: '#e0e0e0',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#6e6e6e',
            },
            '&:hover fieldset': {
              borderColor: '#e0e0e0',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#e0e0e0',
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#e0e0e0',
          borderRadius: '50%',
          width: '48px',
          height: '48px',
          padding: '8px',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        },
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
    },
    text: {
      primary: '#333',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '8px 16px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#fff',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#1976d2',
          borderRadius: '50%',
          width: '48px',
          height: '48px',
          padding: '8px',
        },
      },
    },
  },
});

function App() {
  const { darkMode } = useSelector(state => state.settings);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [openTranslator, setOpenTranslator] = useState(false);

  const theme = darkMode ? darkTheme : lightTheme;

  const handleOpenTranslator = () => {
    setOpenTranslator(true);
  };

  const handleCloseTranslator = () => {
    const realTimeTranslatorRef = document.querySelector('.real-time-translator');
    if (realTimeTranslatorRef && realTimeTranslatorRef.stopMicrophone) {
      realTimeTranslatorRef.stopMicrophone();
    }
    setOpenTranslator(false);
    // refresh page to stop microphone
    window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Router>
        <Navbar />
        <Dialog open={openTranslator} onClose={handleCloseTranslator} fullWidth maxWidth="md" sx={{ borderRadius: '24px' }}>
          <RealTimeTranslator className="real-time-translator" onStop={handleCloseTranslator} />
        </Dialog>
        <Fab
          color="primary"
          aria-label="translate"
          onClick={handleOpenTranslator}
          sx={{
            position: 'fixed',
            bottom: { xs: 80, sm: 50, md: 50 },
            right: 16,
            // add opacity on hover
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <TranslateIcon />
        </Fab>
        <CookiesPopup />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/itinerary" element={<Itinerary />} />
          <Route path="/currency" element={<CurrencyConverter />} />
          <Route path="/destination" element={<CountryInfo />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/realtimetranslator" element={<RealTimeTranslator />} />
        </Routes>
        {isMobile ? <BottomNav /> : <Footer sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} />}
      </Router>
    </ThemeProvider>
  );
}

export default App;