import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, MenuItem, Typography, Box, Container, Card, Button, CircularProgress } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useTranslation } from 'react-i18next';

const CurrencyConverter = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(1);
  const [fromCurrency, setFromCurrency] = useState('USD');
  const [toCurrency, setToCurrency] = useState('EUR');
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchCurrencies = async () => {
      const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
      setCurrencies(Object.keys(response.data.rates));
      setLoading(false);
    };
    fetchCurrencies();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchExchangeRate = async () => {
      const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`);
      setExchangeRate(response.data.rates[toCurrency]);
      setLoading(false);
    };
    fetchExchangeRate();
  }, [fromCurrency, toCurrency]);

  const handleSwap = () => {
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 10 }}>
      <Box sx={{ mt: 14, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" gutterBottom align="center" sx={{ mb: 2 }}>
          {t('convert_currency')}
        </Typography>
        {loading && <CircularProgress />}
        {!loading && (
          <Card elevation={4} sx={{ p: 4, width: '100%', maxWidth: 480 }}>
            <TextField
              label={t('amount')}
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
            />
            <TextField
              label={t('from')}
              select
              value={fromCurrency}
              onChange={(e) => setFromCurrency(e.target.value)}
              fullWidth
              sx={{ mt: 2, mb: 1 }}
            >
              {currencies.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
            <Button onClick={handleSwap} sx={{ mb: 1 }}>
              <SwapHorizIcon />
            </Button>
            <TextField
              label={t('to')}
              select
              value={toCurrency}
              onChange={(e) => setToCurrency(e.target.value)}
              fullWidth
              sx={{ mt: 1, mb: 2 }}
            >
              {currencies.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
            {exchangeRate && (
              <Typography variant="h4">
                {amount} {fromCurrency} = {(amount * exchangeRate).toFixed(2)} {toCurrency}
              </Typography>
            )}
          </Card>
        )}
      </Box>
    </Container>
  );
};

export default CurrencyConverter;
