import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ mt: 14, mb: 10 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          {t('about_us')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('about_us_description')}
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUs;
